html {
  overflow-x: hidden;
}

body {
  font-family: "Quicksand", sans-serif;
  color: #17191c;
}

.vimeo-wrapper {
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.overlay-custom::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8421743697478992) 19%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 100%;
  z-index: 2;
}

.vimeo-wrapper iframe {
  width: 120vw;
  height: 66.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 110vh;
  min-width: 197.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.font-quicksand-light {
  font-family: "Quicksand Light", sans-serif;
}

.font-quicksand {
  font-family: "Quicksand", sans-serif;
}

.font-quicksand-medium {
  font-family: "Quicksand Medium";
}

.font-semibold {
  font-family: "Quicksand SemiBold", sans-serif !important;
}

.font-quicksand-bold {
  font-family: "Quicksand Bold", sans-serif !important;
}

@font-face {
  font-family: quicksand light;
  font-style: normal;
  font-weight: 300;
  src: local("Quicksand Light"),
    url("./assets/fonts/Quicksand-Light.ttf") format("ttf");
}

@font-face {
  font-family: quicksand;
  font-style: normal;
  font-weight: 400;
  src: local("Quicksand Regular"),
    url("./assets/fonts/Quicksand-Regular.ttf") format("ttf");
}

@font-face {
  font-family: quicksand medium;
  font-style: normal;
  font-weight: 500;
  src: local("Quicksand Medium"),
    url("./assets/fonts/Quicksand-Medium.ttf") format("ttf");
}

@font-face {
  font-family: quicksand semibold;
  font-style: normal;
  font-weight: 600;
  src: local("Quicksand SemiBold"),
    url("./assets/fonts/Quicksand-SemiBold.ttf") format("ttf");
}

@font-face {
  font-family: quicksand Bold;
  font-style: normal;
  font-weight: 700;
  src: local("Quicksand Bold"),
    url("./assets/fonts/Quicksand-Bold.ttf") format("ttf");
}
